import { useNavigation } from "@remix-run/react";

type SectionProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export function Box({ children, ...rest }: SectionProps) {
  return (
    <div className="max-w-lg mx-auto mt-12" {...rest}>
      {children}
    </div>
  );
}

export default function GlobalLoadingBar() {
  const { state } = useNavigation();

  return (
    <div
      data-hidden={state === "idle"}
      className="fixed left-0 w-full right-0 top-0 z-30 h-0 transition-opacity duration-500 data-[hidden=true]:opacity-0"
    >
      <div className="animate-pulse">
        <div
          className="
            from-blue-300/50 to-white/90 h-1 w-full scale-x-[20px] transform-gpu animate-[loading-bar_1.5s_ease_infinite]
            rounded-md bg-primary-900 bg-gradient-to-r
          "
        />
      </div>
    </div>
  );
}
