import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";

import "./index.css";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { TooltipProvider } from "./components/ui/tooltip";
import GlobalLoadingBar from "./components/layout";

export function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <html
      lang="en"
      className="dark [scrollbar-gutter:stable] [scrollbar-color:#949494_#110f26] [color-scheme:dark] print:[color-scheme:light]"
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="dark:bg-slate-900 text-black bg-white dark:text-white">
        <GlobalLoadingBar />
        <TooltipProvider>{children}</TooltipProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  console.log(error);

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return <h1>Error: {error.status}</h1>;
  }

  return (
    <div className="bg-no-repeat bg-cover bg-gradient-to-tl from-orange-950 via-slate-900 to-slate-800 min-h-screen py-12">
      <div className="bg-white/10 text-center mx-auto max-w-md border-slate-500/60 border shadow-xl rounded-xl p-8">
        <ExclamationTriangleIcon className="w-24 h-24 text-slate-500 mx-auto my-4" />
        <h2 className="text-2xl my-2 font-bold text-center">
          There was a problem.
        </h2>
        <p>
          We encountered an unexpected error.
          <br /> Please try again later.
          <br />
          Contact support if the problem persists.
        </p>
      </div>
    </div>
  );
}
